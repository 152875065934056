import { CssBaseline, Paper } from "@material-ui/core";
import styled, { createGlobalStyle } from "styled-components/macro";

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }
  body {
    background: ${(props) => props.theme.palette.background.default};
  }
`;

const Root = styled.div`
  max-width: 820px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`;

const Wrapper = styled(Paper)``;

const Wrapper1 = styled(Paper)`
  padding: 10px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

const AuthNoBrand = ({ children }) => {
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Wrapper>
        <Wrapper1>{children}</Wrapper1>
      </Wrapper>
    </Root>
  );
};

export default AuthNoBrand;
