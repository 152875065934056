// Theme
export const THEME_SET = "THEME_SET";
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};

// Auth
export const AUTH_SIGN_IN_REQUEST = "AUTH_SIGN_IN_REQUEST";
export const AUTH_SIGN_IN_SUCCESS = "AUTH_SIGN_IN_SUCCESS";
export const AUTH_SIGN_IN_FAILURE = "AUTH_SIGN_IN_FAILURE";
export const AUTH_SIGN_OUT = "AUTH_SIGN_OUT";
export const AUTH_SIGN_UP_REQUEST = "AUTH_SIGN_UP_REQUEST";
export const AUTH_SIGN_UP_SUCCESS = "AUTH_SIGN_UP_SUCCESS";
export const AUTH_SIGN_UP_FAILURE = "AUTH_SIGN_UP_FAILURE";
export const AUTH_RESET_PASSWORD_REQUEST = "AUTH_RESET_PASSWORD_REQUEST";
export const AUTH_RESET_PASSWORD_SUCCESS = "AUTH_RESET_PASSWORD_SUCCESS";
export const AUTH_RESET_PASSWORD_FAILURE = "AUTH_RESET_PASSWORD_FAILURE";

export const CHANGE_SELFIE_USER = "CHANGE_SELFIE_USER";
export const CHANGE_EMPRESA = "CHANGE_EMPRESA";
export const CHANGE_LAST_ACCESS = "CHANGE_LAST_ACCESS";
export const CHANGE_NOTIFICACOES = "CHANGE_NOTIFICACOES";
export const TOKEN_MAPBOX =
  "pk.eyJ1IjoicHNhaW5mbyIsImEiOiJja2xoejdnOWswNHdtMndxcjZzZDBjNmwyIn0.Ilns-aRd6SG7J1wtnwbKjQ";
export const LATITUDE_DEFAULT = -15.894055850789314;
export const LONGITUDE_DEFAULT = -52.257905584611386;

export const langHighcharts = {
  decimalPoint: ",",
  thousandsSep: ".",
  contextButtonTitle: "Menu do Gráfico",
  downloadCSV: "Download CSV",
  downloadJPEG: "Download JPEG",
  downloadPDF: "Download PDF",
  downloadPNG: "Download PNG",
  downloadSVG: "Download SVG",
  downloadXLS: "Download XLS",
  drillUpText: "Voltar Para {series.name}",
  loading: "Carregando...",
  months: [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ],
  navigation: {
    popup: {
      addButton: "Adicionar",
      background: "Fundo",
    },
  },
  tooltip: {
    yDecimals: 2,
  },
  noData: "Nenhum dado para exibir",
  openInCloud: "Abrir no Highcharts Cloud",
  printChart: "Imprimir Gráfico",
  viewFullscreen: "Visualizar em tela cheia",
  resetZoom: "Resetar zoom",
  resetZoomTitle: "Resetar zoom nível 1:1",
  shortMonths: [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ],
  viewData: "Visualizar dados",
  weekdays: [
    "Domingo",
    "Segunda-Feira",
    "Terça-Feira",
    "Quarta-Feira",
    "Quinta-Feira",
    "Sexta-Feira",
    "Sábado",
  ],
};

export const SEXO = [
  {
    cod: "MASCULINO",
    name: "Masculino",
  },
  {
    cod: "FEMININO",
    name: "Feminino",
  },
];

export const DOMINIO_STATUS_EMPRESA = Object.freeze({
  ATIVA: { cod: "ATIVA", name: "Ativa" },
  SUSPENSA: { cod: "SUSPENSA", name: "Suspensa" },
  INATIVA: { cod: "INATIVA", name: "Inativa" },
});

export const DOMINIO_TIPO_TAXA = [
  {
    cod: "WEB",
    name: "Web",
  },
  {
    cod: "TOTEM",
    name: "Totem",
  },
];

export const DOMINIO_PRODUTO = [
  {
    cod: "DETRAN",
    name: "Detran",
  },
  {
    cod: "BOLETOS",
    name: "Boletos",
  },
  {
    cod: "EMPRESTIMO_CARTAO_CREDITO",
    name: "Empréstimo Cartão de Crédito",
  },
];

export const DOMINIO_AUTORIZADORA = [
  {
    cod: "Banese",
    name: "Banese",
  },
  {
    cod: "Bin",
    name: "Bin",
  },
  {
    cod: "Brasilcard",
    name: "Brasilcard",
  },
  {
    cod: "Cielo",
    name: "Cielo",
  },
  {
    cod: "Convcard",
    name: "Convcard",
  },
  {
    cod: "Getnet",
    name: "Getnet",
  },
  {
    cod: "Getnetlac",
    name: "Getnetlac",
  },
  {
    cod: "Globalpay",
    name: "Globalpay",
  },
  {
    cod: "Pagseguro",
    name: "Pagseguro",
  },
  {
    cod: "Redetrel",
    name: "Redetrel",
  },
  {
    cod: "Safrapay",
    name: "Safrapay",
  },
  {
    cod: "Senff",
    name: "Senff",
  },
  {
    cod: "Softnex",
    name: "Softnex",
  },
  {
    cod: "Stone",
    name: "Stone",
  },
  {
    cod: "Telenet",
    name: "Telenet",
  },
  {
    cod: "Tendencia",
    name: "Tendencia",
  },
  {
    cod: "Userede",
    name: "Userede",
  },
  {
    cod: "Vero",
    name: "Vero",
  },
];

export const ESTADO_CIVIL = [
  {
    cod: "CASADO",
    name: "Casado",
  },
  {
    cod: "SOLTEIRO",
    name: "Solteiro",
  },
  {
    cod: "SEPARADO",
    name: "Separado",
  },
  {
    cod: "VIUVO",
    name: "Viúvo",
  },
  {
    cod: "DIVORCIADO",
    name: "Divorciado",
  },
];

export const ESTADOS_JSON = [
  {
    nome: "Acre",
    sigla: "AC",
  },
  {
    nome: "Alagoas",
    sigla: "AL",
  },
  {
    nome: "Amapá",
    sigla: "AP",
  },
  {
    nome: "Amazonas",
    sigla: "AM",
  },
  {
    nome: "Bahia",
    sigla: "BA",
  },
  {
    nome: "Ceará",
    sigla: "CE",
  },
  {
    nome: "Distrito Federal",
    sigla: "DF",
  },
  {
    nome: "Espírito Santo",
    sigla: "ES",
  },
  {
    nome: "Goiás",
    sigla: "GO",
  },
  {
    nome: "Maranhão",
    sigla: "MA",
  },
  {
    nome: "Mato Grosso",
    sigla: "MT",
  },
  {
    nome: "Mato Grosso do Sul",
    sigla: "MS",
  },
  {
    nome: "Minas Gerais",
    sigla: "MG",
  },
  {
    nome: "Pará",
    sigla: "PA",
  },
  {
    nome: "Paraíba",
    sigla: "PB",
  },
  {
    nome: "Paraná",
    sigla: "PR",
  },
  {
    nome: "Pernambuco",
    sigla: "PE",
  },
  {
    nome: "Piauí",
    sigla: "PI",
  },
  {
    nome: "Rio de Janeiro",
    sigla: "RJ",
  },
  {
    nome: "Rio Grande do Norte",
    sigla: "RN",
  },
  {
    nome: "Rio Grande do Sul",
    sigla: "RS",
  },
  {
    nome: "Rondônia",
    sigla: "RO",
  },
  {
    nome: "Roraima",
    sigla: "RR",
  },
  {
    nome: "Santa Catarina",
    sigla: "SC",
  },
  {
    nome: "São Paulo",
    sigla: "SP",
  },
  {
    nome: "Sergipe",
    sigla: "SE",
  },
  {
    nome: "Tocantins",
    sigla: "TO",
  },
];

export const MESSAGE_VALIDATION_INPUT = {
  REQUERID_FIELD: "Campo Obrigatório!",
  INVALID_EMAIL_FIELD: "Email Inválido!",
  INVALID_DATE: "Formato de data inválida!",
};

export const DOMINIO_ESTADO_CIVIL = Object.freeze({
  SOLTEIRO: { cod: 1, desc: "Solteiro", name: "SOLTEIRO" },
  CASADO: { cod: 2, desc: "Casado", name: "CASADO" },
  SEPARADO: { cod: 3, desc: "Separado", name: "SEPARADO" },
  DIVORCIADO: { cod: 4, desc: "Divorciado", name: "DIVORCIADO" },
  VIUVO: { cod: 5, desc: "Viúvo", name: "VIUVO" },
});

export const DOMINIO_SIM_NAO = Object.freeze({
  SIM: { cod: 1, desc: "Sim", name: "SIM" },
  NAO: { cod: 0, desc: "Não", name: "NAO" },
});

export const DOMINIO_SEVERITY_MENSAGEM = Object.freeze({
  ERROR: { cod: "error", desc: "Erro", name: "error" },
  WARNING: { cod: "warning", desc: "Atenção", name: "warning" },
  INFO: { cod: "info", desc: "Informativa", name: "info" },
  SUCCESS: { cod: "success", desc: "Sucesso", name: "success" },
});

export const DOMINIO_TIPO_PERFIL = Object.freeze({
  ADMINISTRADORA: { cod: 1, desc: "Administrador", name: "ADMINISTRADORA" },
  GESTOR: { cod: 2, desc: "Parceiro", name: "PARCEIRO" },
});

export const DOMINIO_TIPO_NOTIFICACAO = Object.freeze({
  EMAIL: { cod: 1, desc: "E-mail", name: "EMAIL" },
  SISTEMA: { cod: 2, desc: "Sistema", name: "SISTEMA" },
});

export const DOMINIO_STATUS_LICENCIAMENTO = [
  {
    cod: 1,
    desc: "Aguardando Confirmação",
    name: "AGUARDANDO_CONFIRMARCAO",
  },
  {
    cod: 2,
    desc: "Pagamento Confirmado",
    name: "PAGAMENTO_CONFIRMADO",
  },
  {
    cod: 3,
    desc: "Aguardando Compra 3DS",
    name: "AGUARDANDO_3DS",
  },
];

export const DOMINIO_ATIVO_INATIVO = Object.freeze({
  ATIVO: { cod: 1, desc: "Ativo", name: "ATIVO" },
  INATIVO: { cod: 0, desc: "Inativo", name: "INATIVO" },
});

export const DOMINIO_PAPEL_PERFIL = Object.freeze({
  ADMINISTRADORA: { cod: 1, desc: "Administrador", name: "ADMINISTRADORA" },
  GESTOR: { cod: 2, desc: "Gestor", name: "GESTOR" },
  COLABORADOR: { cod: 3, desc: "Colaborador", name: "COLABORADOR" },
});

export const DOMINIO_PUBLICO_ALVO_MENSAGEM = Object.freeze({
  INDIVIDUAL: { cod: 1, desc: "Individual", name: "INDIVIDUAL" },
  TODOS: { cod: 2, desc: "Todos", name: "TODOS" },
});
