import React from "react";
import { PlusSquare, ShoppingCart, Users } from "react-feather";
import { BiMessageAltCheck } from "react-icons/bi";
import { CgProfile } from "react-icons/cg";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { RiDashboardLine, RiLockPasswordLine } from "react-icons/ri";
import async from "../components/Async";
import Empresas from "../pages/empresa/Empresas";
import { AiOutlineBank } from "react-icons/ai";
import { TiMessages } from "react-icons/ti";

const RelatorioPix = async(() =>
  import("../pages/relatorio/licenciamentos/RelatorioPix")
);

const CheckoutBoleto = async(() =>
  import("../pages/pagamentoBoleto/CheckoutBoleto")
);

const AtivarTerminal = async(() => import("../pages/ativarTerminal"));

const PontosVenda = async(() =>
  import("../pages/cadastro/pontosVenda/PontosVenda")
);
const RelatorioBoletos = async(() =>
  import("../pages/relatorio/licenciamentos/RelatorioBoletos")
);
const Empresa = async(() => import("../pages/cadastro/empresa/Empresa"));
const Taxa = async(() => import("../pages/cadastro/taxa/Taxa"));
const Logico = async(() => import("../pages/cadastro/logico/Logico"));
const ConsultaDebito = async(() => import("../pages/pagamento/ConsultaDebito"));
const ListaDebitos = async(() => import("../pages/pagamento/ListaDebitos"));
const Checkout = async(() => import("../pages/pagamento/Checkout"));

const ConsultaDebitoWeb = async(() =>
  import("../pages/pagamentoWeb/ConsultaDebito")
);
const ListaDebitosWeb = async(() =>
  import("../pages/pagamentoWeb/ListaDebitos")
);
const CheckoutWeb = async(() => import("../pages/pagamentoWeb/Checkout"));

const Notificacao = async(() =>
  import("../pages/cadastro/notificacao/Notificacao")
);

const RelatorioLicenciamento = async(() =>
  import("../pages/relatorio/licenciamentos/RelatorioLicenciamento")
);

const Mensagem = async(() => import("../pages/mensagem/Mensagem"));
const ManterCliente = async(() =>
  import("../pages/cadastro/cliente/ManterCliente")
);
const ResetPasswordInputSenha = async(() =>
  import("../pages/auth/ResetPasswordInputSenha")
);
const RelatorioLogUsuario = async(() =>
  import("../pages/relatorio/logusuario/RelatorioLogUsuario")
);
const Parametro = async(() => import("../pages/cadastro/parametro/Parametro"));
const AlterarSenha = async(() =>
  import("../pages/cadastro/alterarsenha/AlterarSenha")
);
const Perfis = async(() => import("../pages/cadastro/perfis/Perfis"));
const PesquisarCliente = async(() =>
  import("../pages/cadastro/cliente/PesquisarCliente")
);
const RelatorioUsuarios = async(() =>
  import("../pages/relatorio/usuarios/RelatorioUsuarios")
);
const Dashboard = async(() => import("../pages/Analytics"));

// Guards
const AuthGuard = async(() => import("../components/AuthGuard"));

// Auth components
const SignUp = async(() => import("../pages/auth/SignUp"));
const SignIn = async(() => import("../pages/auth/SignIn"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));
const SignInCadastro = async(() => import("../pages/auth/SignInCadastro"));

// Dashboards components
const Settings = async(() => import("../pages/manutencao/Settings"));

// Protected routes
const ProtectedPage = async(() => import("../pages/protected/ProtectedPage"));

const suporteRoutes = {
  path: "/",
  header: "Suporte",
  permission: [],
  perfil: ["SUPORTE", "FINANCEIRO"],
  children: [],
  component: null,
  guard: AuthGuard,
};

const mensagensRoutes = {
  id: "Mensagens",
  path: "/mensagens",
  perfil: ["SUPORTE", "FINANCEIRO"],
  icon: <BiMessageAltCheck />,
  permission: [],
  component: Mensagem,
  children: null,
  guard: AuthGuard,
};

const notificacaoRoutes = {
  id: "Notificações",
  path: "/notificacao/manter",
  permission: [],
  icon: <TiMessages />,
  component: Notificacao,
  children: null,
  guard: AuthGuard,
};

const consultasRoutes = {
  id: "Cadastros",
  path: "/cadastros",
  permission: [
    "manutencao_perfis",
    "manter_parametros",
    "manter_pessoa",
    "manter_taxa",
    "manter_logico",
  ],
  icon: <PlusSquare />,
  guard: AuthGuard,
  children: [
    {
      path: "/cliente/cadastro/:idMutuarioParam",
      hiddenSidebar: true,
      permission: ["manter_pessoa"],
      component: ManterCliente,
      guard: AuthGuard,
    },
    {
      path: "/cliente/cadastro",
      hiddenSidebar: true,
      permission: ["manter_pessoa"],
      component: ManterCliente,
      guard: AuthGuard,
    },
    {
      name: "Pessoa",
      path: "/cliente/pesquisa",
      permission: ["manter_pessoa"],
      component: PesquisarCliente,
      guard: AuthGuard,
    },

    {
      path: "/cadastros/perfis",
      name: "Perfis",
      permission: ["manutencao_perfis"],
      component: Perfis,
      guard: AuthGuard,
    },
    {
      path: "/cadastros/parametros",
      name: "Configurações",
      permission: ["manter_parametros"],
      component: Parametro,
      guard: AuthGuard,
    },
    {
      path: "/cadastros/taxas",
      name: "Taxas",
      permission: ["manter_taxa"],
      component: Taxa,
      guard: AuthGuard,
    },
    {
      path: "/cadastros/logico",
      name: "Canal de Venda",
      permission: ["manter_logico"],
      component: Logico,
      guard: AuthGuard,
    },
  ],
  component: null,
};

const pagesRoutes = {
  id: "Relatórios",
  path: "/relatorios",
  permission: ["rel_log_usuario", "relatorio_usuarios", "rel_licenciamento"],
  icon: <HiOutlineDocumentReport />,
  children: [
    {
      path: "/relatorios/usuarios",
      name: "Usuários",
      permission: ["relatorio_usuarios"],
      component: RelatorioUsuarios,
      guard: AuthGuard,
    },
    {
      path: "/relatorios/auditoria",
      name: "Log do Usuário",
      permission: ["rel_log_usuario"],
      component: RelatorioLogUsuario,
      guard: AuthGuard,
    },
    {
      path: "/relatorios/licenciamento",
      name: "Pagamentos Detran",
      permission: ["rel_licenciamento"],
      component: RelatorioLicenciamento,
      guard: AuthGuard,
    },
    {
      path: "/relatorios/boletos",
      name: "Pagamentos de Boletos",
      permission: ["rel_boletos"],
      component: RelatorioBoletos,
      guard: AuthGuard,
    },
    {
      path: "/relatorios/pix",
      name: "Pagamentos de PIX",
      permission: ["rel_pix"],
      component: RelatorioPix,
      guard: AuthGuard,
    },
  ],
  component: null,
};

const authRoutesNoBrand = {
  id: "AuthNoBrand",
  path: "/",
  permission: [],
  icon: <Users />,
  children: [
    {
      path: "/autoatendimento",
      component: ConsultaDebito,
    },
    {
      path: "/ativarTerminal",
      component: AtivarTerminal,
    },
    {
      path: "/debitos/:codigo",
      component: ListaDebitos,
    },
    {
      path: "/checkout/:codigo",
      component: Checkout,
    },
    {
      path: "/detran",
      component: ConsultaDebitoWeb,
    },
    {
      path: "/",
      component: CheckoutBoleto,
    },
  ],
  component: null,
};

const authRoutes = {
  id: "Auth",
  path: "/",
  permission: [],
  icon: <Users />,
  children: [
    {
      path: "/auth",
      name: "Login",
      component: SignIn,
    },
    {
      path: "/auth/posCadastro/:token",
      hiddenSidebar: true,
      component: SignInCadastro,
    },
    {
      path: "/auth/reset-password",
      name: "Esqueci Minha Senha",
      component: ResetPassword,
    },
    {
      path: "/recuperarSenha/:hash",
      name: "Alterar Senha",
      component: ResetPasswordInputSenha,
    },
    {
      path: "/cadastro",
      name: "Cadastro",
      component: SignUp,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },

    {
      path: "/debitosWeb/:codigo",
      component: ListaDebitosWeb,
    },
    {
      path: "/checkoutWeb/:codigo",
      component: CheckoutWeb,
    },
  ],
  component: null,
};

const dashboardsRoutes = {
  id: "Início",
  path: "/app",
  permission: [],
  icon: <RiDashboardLine />,
  containsHome: true,
  component: Dashboard,
  guard: AuthGuard,
};

const meuPontoRoutes = {
  path: "/",
  header: "Minha Área",
  permission: [],
  children: [],
  component: null,
  guard: AuthGuard,
};

const alterarSenha = {
  id: "Alterar Senha",
  permission: [],
  path: "/alterarSenha",
  icon: <RiLockPasswordLine />,
  component: AlterarSenha,
  children: null,
  guard: AuthGuard,
};

const meuPerfil = {
  id: "Meus Dados",
  permission: [],
  path: "/manutencao/profile",
  icon: <CgProfile />,
  component: Settings,
  children: null,
  guard: AuthGuard,
};

const empresasRoutes = {
  id: "Credenciadas",
  path: "/credenciadas",
  perfil: ["SUPORTE", "FINANCEIRO"],
  icon: <AiOutlineBank />,
  permission: [],
  component: Empresas,
  children: null,
  guard: AuthGuard,
};

const pontosVendaRoutes = {
  id: "Pontos de Venda",
  path: "/pontosVenda",
  perfil: ["SUPORTE", "FINANCEIRO"],
  icon: <AiOutlineBank />,
  permission: [],
  component: PontosVenda,
  children: null,
  guard: AuthGuard,
};

// This route is only visible while signed in
const protectedPageRoutes = {
  id: "Private",
  permission: [],
  path: "/app",
  component: ProtectedPage,
  children: null,
  guard: AuthGuard,
};

const empresaRoute = {
  id: "Empresa",
  path: "/empresa",
  permission: [],
  icon: <ShoppingCart />,
  component: Empresa,
  children: null,
  guard: AuthGuard,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  dashboardsRoutes,
  consultasRoutes,
  pagesRoutes,
  suporteRoutes,
  empresasRoutes,
  pontosVendaRoutes,
  notificacaoRoutes,
  empresaRoute,
  meuPontoRoutes,
  mensagensRoutes,
  meuPerfil,
  alterarSenha,
];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

export const authLayoutRoutesNoBrand = [authRoutesNoBrand];

// Routes that are protected
export const protectedRoutes = [protectedPageRoutes];

// Routes visible in the sidebar
export const sidebarRoutes = [
  dashboardsRoutes,
  consultasRoutes,
  pagesRoutes,
  suporteRoutes,
  empresasRoutes,
  pontosVendaRoutes,
  mensagensRoutes,
  meuPontoRoutes,
  notificacaoRoutes,
  meuPerfil,
  alterarSenha,
];
