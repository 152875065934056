const hasPermission = (permissionRequest, usuarioLogado) => {
  if (usuarioLogado === undefined || usuarioLogado === null || usuarioLogado.permissions.length <= 0) {
    return false;
  }

  const findPermission = usuarioLogado.permissions.filter((permission) => {
    return permission.identificador === permissionRequest;
  });

  return findPermission !== undefined && findPermission.length > 0;
}

const hasPermissions = (permissionsRequest, usuarioLogado) => {
  if (!Array.isArray(permissionsRequest)) {
    return false;
  }

  if (permissionsRequest.length === 0 || permissionsRequest[0] === null) { // Que n precisa de permissão
    return true;
  }

  if (usuarioLogado.permissions.length <= 0) {
    return false;
  }

  const findPermission = usuarioLogado.permissions.filter((permission) => {
    return permissionsRequest.includes(permission.identificador);
  });

  return findPermission !== undefined && findPermission.length > 0;
}


const hasPaper = (papers, usuarioLogado) => {
  if (usuarioLogado === undefined || usuarioLogado === null || !usuarioLogado.profile) {
    return false;
  }

  const findPermission = papers.filter((paper) => {
    return paper === usuarioLogado.profile.papel;
  });

  return findPermission !== undefined && findPermission.length > 0;
}

const PermissionUtil = {
  hasPermission,
  hasPermissions,
  hasPaper
}

export default PermissionUtil;
