//import axios from "../utils/axios";
import Axios from "axios";

function buscarPessoaUsuarioLogado() {
  return new Promise((resolve, reject) => {
    Axios.get(`/pessoa`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function updatePessoaUsuarioLogado({
  documento,
  email,
  telefone,
  nome,
  dataNascimento,
  sexo,
}) {
  return new Promise((resolve, reject) => {
    const vo = {
      email,
      telefone,
      nome,
      dataNascimento,
      documento,
      sexo,
    };
    Axios.put(`/pessoa`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function buscarPorDocumento(cpf) {
  return new Promise((resolve, reject) => {
    Axios.get(`/pessoa/buscarPorDocumento/${cpf}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function buscarPorId(id) {
  return new Promise((resolve, reject) => {
    Axios.get(`/pessoa/buscarPorId/${id}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function pesquisar(documento, email, nome, razaoSocial, page = 0) {
  return new Promise((resolve, reject) => {
    var vo = {
      documento: documento,
      email: email,
      nome: nome,
      razaoSocial: razaoSocial,
    };

    Axios.post(`/pessoa/pesquisa/${page}`, vo)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function save({
  idPessoa,
  documento,
  email,
  telefone,
  tipoPessoa,
  sexo,
  nome,
  dataNascimento,
}) {
  return new Promise((resolve, reject) => {
    var vo = {
      idPessoa,
      documento,
      email,
      telefone,
      sexo,
      tipoPessoa,
      nome,
      dataNascimento,
    };

    Axios.post(`/pessoa/`, vo)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function relatorioClientes({ dataInicio, dataFim, page }) {
  return new Promise((resolve, reject) => {
    var vo = {
      dataInicio,
      dataFim,
    };

    Axios.post(`/pessoa/relatorioClientes/${page}`, vo)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const PessoaService = {
  buscarPessoaUsuarioLogado,
  updatePessoaUsuarioLogado,
  buscarPorDocumento,
  pesquisar,
  save,
  buscarPorId,
  relatorioClientes,
};

export default PessoaService;
