import { IconButton as MuiIconButton, Tooltip } from "@material-ui/core";
import Axios from "axios";
import React from "react";
import { Power } from "react-feather";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { signOut } from "../redux/actions/authActions";
import AsyncStorageUtil from "../utils/AsyncStorageUtil";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function UserDropdown() {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleSignOut = async () => {
    history.push("/auth");
    AsyncStorageUtil.setToken(undefined);
    Axios.defaults.headers.common.Authorization = undefined;
    await dispatch(signOut());
  };

  return (
    <React.Fragment>
      <Tooltip title="Sair">
        <IconButton
          aria-owns={Boolean(null) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={handleSignOut}
          color="inherit"
        >
          <Power />
        </IconButton>
      </Tooltip>
    </React.Fragment>
  );
}

export default UserDropdown;
