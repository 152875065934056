//import axios from "../utils/axios";
import Axios from "axios";

function vinculaLote(pontosVenda, empresa) {
  return new Promise((resolve, reject) => {
    const vo = {
      pontosVenda,
      empresa,
    };

    Axios.post(`/empresaPontoVenda/vinculaLote`, vo)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function buscarVinculadosNaoVinculados(idEmpresa) {
  return new Promise((resolve, reject) => {
    Axios.get(`/empresaPontoVenda/buscarVinculadosNaoVinculados/${idEmpresa}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function buscarVinculados() {
  return new Promise((resolve, reject) => {
    Axios.get(`/empresaPontoVenda`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const EmpresaPontoVendaService = {
  vinculaLote,
  buscarVinculados,
  buscarVinculadosNaoVinculados,
};

export default EmpresaPontoVendaService;
