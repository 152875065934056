import AsyncStorage from "@callstack/async-storage";

async function removeToken() {
  await AsyncStorage.removeItem("token");
}

async function setToken(token) {
  await AsyncStorage.setItem("token", token);
}

async function getToken() {
  await AsyncStorage.getItem("token");
}

async function getSequencial() {
  await AsyncStorage.getItem("sequencialTef");
}

async function setSequencial(seq) {
  console.log(seq);
  await AsyncStorage.setItem("sequencialTef", seq);
}

export default {
  getToken,
  setToken,
  removeToken,
  getSequencial,
  setSequencial,
};
