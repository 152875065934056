import {
  Box,
  Button,
  Card,
  CardContent,
  DialogActions,
  Divider as MuiDivider,
  Grid as MuiGrid,
  Paper,
  Typography,
} from "@material-ui/core";
import { NewReleases } from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import { useFormik } from "formik";
import { Column } from "primereact/column";
import React, { useState } from "react";
import { Check, Plus } from "react-feather";
import { Helmet } from "react-helmet";
import { AiOutlineBank } from "react-icons/ai";
import { FaBan } from "react-icons/fa";
import { ImCancelCircle } from "react-icons/im";
import styled from "styled-components/macro";
import * as Yup from "yup";
import Alert from "../../components/alert/Alert";
import DataTable from "../../components/datatable/DataTable";
import Dialog from "../../components/Dialog";
import BreadcrumbsNav from "../../components/icarus/BreadcrumbsNav";
import EditarButtonTable from "../../components/icarus/buttons/EditarButtonTable";
import PesquisarButton from "../../components/icarus/buttons/PesquisarButton";
import TextField from "../../components/icarus/textfield/TextField";
import TextFieldCNPJ from "../../components/icarus/textfield/TextFieldCNPJ";
import Loading from "../../components/Loading";
import EmpresaService from "../../services/EmpresaService";
import CredenciadaPontoVenda from "./CredenciadaPontoVenda";
import EditarEmpresa from "./EditarEmpresa";

const validationSchema = Yup.object().shape({
  nomeFiltro: Yup.string().nullable().min(3).max(255),
  documento: Yup.string().nullable().min(3).max(255),
  tipo: Yup.string().nullable(),
  statusNfse: Yup.string().nullable(),
});

const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);

function Empresas(props) {
  const [objPage, setObjPage] = useState();
  const [showDialog, setShowDialog] = useState(false);
  const [carregando, setCarregando] = useState(false);
  const [empresaSelecionado, setEmpresaSelecionado] = useState();
  const [page, setPage] = useState(0);
  const [selecionado, setSelecionado] = useState();
  const [showDialogPontosVenda, setShowDialogPontosVenda] = useState(false);

  const formik = useFormik({
    initialValues: {
      nomeFiltro: undefined,
      documento: undefined,
      tipo: undefined,
      statusNfse: undefined,
      modoCobranca: undefined,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      pesquisar(0);
    },
  });

  const pesquisar = (page) => {
    setPage(page);
    setCarregando(true);

    EmpresaService.pesquisa(
      formik.values.nomeFiltro,
      page,
      formik.values.documento,
      formik.values.tipo,
      formik.values.statusNfse,
      formik.values.modoCobranca
    )
      .then((response) => {
        setObjPage(response);
      })
      .finally(() => setCarregando(false));
  };

  const onPageDataTable = (page) => {
    pesquisar(page);
  };

  const editar = (data) => {
    setEmpresaSelecionado(data);
    setShowDialog(true);
  };

  const actionTemplate = (rowData, column) => {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item>
            <EditarButtonTable onClick={() => editar(rowData)} />
          </Grid>

          <Grid item>
            <Button
              type="button"
              variant="contained"
              title={"Pontos Venda"}
              startIcon={<AiOutlineBank />}
              onClick={() => {
                setEmpresaSelecionado(rowData);
                setShowDialogPontosVenda(true);
              }}
            >
              Pontos Venda
            </Button>
          </Grid>
        </Grid>
      </>
    );
  };

  const renderTable = () => {
    return (
      <DataTable
        carregando={carregando}
        objPage={objPage}
        onPage={onPageDataTable}
      >
        <Column
          field="nome"
          header="Nome"
          body={(rowData) => (
            <>
              <span className="p-column-title">Nome</span>
              {rowData.nome}
            </>
          )}
        />

        <Column
          field="documentoFormatado"
          header="CPF/CNPJ"
          body={(rowData) => (
            <>
              <span className="p-column-title">CPF/CNPJ</span>
              {rowData.documentoFormatado}
            </>
          )}
        />

        <Column
          field="email"
          header="Email"
          body={(rowData) => (
            <>
              <span className="p-column-title">Email</span>
              {rowData.email}
            </>
          )}
        />

        <Column
          field="telefoneFormatado"
          header="Telefone"
          body={(rowData) => (
            <>
              <span className="p-column-title">Telefone</span>
              {rowData.telefoneFormatado}
            </>
          )}
        />

        <Column
          field="statusFormatado"
          header="Status"
          body={(rowData) => (
            <>
              <span className="p-column-title">Status</span>
              {rowData.statusFormatado}
            </>
          )}
        />

        <Column body={actionTemplate} header="Ações" />
      </DataTable>
    );
  };

  const handleClose = () => {
    setShowDialogPontosVenda(false);
    pesquisar(page);
  };

  return (
    <React.Fragment>
      <Helmet title="Creadenciadas" />

      <Typography variant="h4" gutterBottom display="inline">
        Creadenciadas
      </Typography>

      <BreadcrumbsNav label="Creadenciadas" />

      <Divider my={6} />

      {showDialog && (
        <EditarEmpresa
          show={showDialog}
          onRequestClose={() => handleClose()}
          data={empresaSelecionado}
        />
      )}

      {showDialogPontosVenda && (
        <CredenciadaPontoVenda
          show={showDialogPontosVenda}
          onClose={() => handleClose()}
          empresa={empresaSelecionado}
        />
      )}

      <Paper elevation={5}>
        <Card>
          <CardContent>
            <Typography component="h5" variant="h5">
              Pesquisar
            </Typography>
            <Box my={6} />
            <form noValidate onSubmit={formik.handleSubmit}>
              <Grid container spacing={6}>
                <Grid item md={4} sm={12} xs={12}>
                  <TextField
                    name="nomeFiltro"
                    label="Nome"
                    fullWidth
                    my={10}
                    value={formik.values.nomeFiltro}
                    error={Boolean(
                      formik.touched.nomeFiltro && formik.errors.nomeFiltro
                    )}
                    helperText={
                      formik.touched.nomeFiltro && formik.errors.nomeFiltro
                    }
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                  <TextFieldCNPJ
                    name="documento"
                    label="CPF/CNPJ"
                    fullWidth
                    my={10}
                    value={formik.values.documento}
                    error={Boolean(
                      formik.touched.documento && formik.errors.documento
                    )}
                    helperText={
                      formik.touched.documento && formik.errors.documento
                    }
                    onBlur={formik.handleBlur}
                    onChange={(e) => formik.setFieldValue("documento", e)}
                  />
                </Grid>

                <Grid item md={10}>
                  {carregando ? (
                    <Loading />
                  ) : (
                    <Grid container spacing={6}>
                      <Grid item>
                        <PesquisarButton />
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={<Plus />}
                          onClick={() => {
                            setShowDialog(true);
                            setEmpresaSelecionado(undefined);
                          }}
                        >
                          Cadastrar
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Paper>
      <Box my={5} />
      <Paper elevation={5}>
        <Card>
          <CardContent>
            <Typography component="h5" variant="h5">
              Resultado
            </Typography>
            <Box my={6} />
            {renderTable()}
          </CardContent>
        </Card>
      </Paper>
    </React.Fragment>
  );
}

export default Empresas;
