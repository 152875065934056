/* eslint-disable prettier/prettier */
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AuthLayoutNoBrand from "../layouts/AuthNoBrand";
import AuthLayout from "../layouts/Auth";
import DashboardLayout from "../layouts/Dashboard";
import Page404 from "../pages/auth/Page404";
import { authLayoutRoutes, authLayoutRoutesNoBrand, dashboardLayoutRoutes, protectedRoutes } from "./index";


const childRoutes = (Layout, routes) => routes.map(({
    component: Component,
    guard,
    children,
    path
}, index) => {
    const Guard = guard || React.Fragment;

    return children ? (children.map((element, index) => {
        const Guard = element.guard || React.Fragment;

        return (
            <Route key={index}
                path={
                    element.path
                }
                exact
                render={
                    (props) => (
                        <Guard>
                            <Layout>
                                <element.component {...props} />
                            </Layout>
                        </Guard>
                    )
                } />
        );
    })) : Component ? (
        <Route key={index}
            path={path}
            exact
            render={
                (props) => (
                    <Guard>
                        <Layout>
                            <Component {...props} />
                        </Layout>
                    </Guard>
                )
            } />
    ) : null;
});


const Routes = () => (
    <Router>
        <Switch>
            {childRoutes(DashboardLayout, dashboardLayoutRoutes)}
            {childRoutes(DashboardLayout, protectedRoutes)}
            {childRoutes(AuthLayout, authLayoutRoutes)}
            {childRoutes(AuthLayoutNoBrand, authLayoutRoutesNoBrand)}
            <Route render={() => (
                <AuthLayout>
                    <Page404 />
                </AuthLayout>)} />

        </Switch>
    </Router>
);

export default Routes;
