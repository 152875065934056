import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  Select,
  Typography,
} from "@material-ui/core";
import { CloudUpload as MuiCloudUpload } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { spacing } from "@material-ui/system";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import * as Yup from "yup";
import Alert from "../../components/alert/Alert";
import Dialog from "../../components/Dialog";
import AlterarButton from "../../components/icarus/buttons/AlterarButton";
import CadastrarButton from "../../components/icarus/buttons/CadastrarButton";
import FormEndereco from "../../components/icarus/FormEndereco";
import TextField from "../../components/icarus/textfield/TextField";
import TextFieldCNPJ from "../../components/icarus/textfield/TextFieldCNPJ";
import TextFieldTelefone from "../../components/icarus/textfield/TextFieldTelefone";
import Loading from "../../components/Loading";
import {
  DOMINIO_STATUS_EMPRESA,
  MESSAGE_VALIDATION_INPUT,
} from "../../constants";
import EmpresaService from "../../services/EmpresaService";

const Card = styled(MuiCard)(spacing);

const Button = styled(MuiButton)(spacing);

const CloudUpload = styled(MuiCloudUpload)(spacing);

const CenteredContent = styled.div`
  text-align: center;
`;

const validationSchema = Yup.object().shape({
  nome: Yup.string()
    .min(5)
    .max(255)
    .required(MESSAGE_VALIDATION_INPUT.REQUERID_FIELD),
  razaoSocial: Yup.string()
    .min(5)
    .max(255)
    .required(MESSAGE_VALIDATION_INPUT.REQUERID_FIELD),
  email: Yup.array()
    .required(MESSAGE_VALIDATION_INPUT.REQUERID_FIELD)
    .min(1, "Informe no minímo um email")
    .max(10, "Permitido no máximo 10 emails")
    .transform(function (value, originalValue) {
      if (this.isType(value) && value !== null) {
        return value;
      }
      let returnedValue = originalValue ? originalValue.split(/[\s,]+/) : [];
      return returnedValue;
    })
    .of(
      Yup.string()
        .required("Informe no mínimo um email")
        .email(({ value }) => `Informe um email válido para ${value}.`)
    ),
  telefone: Yup.string().required(MESSAGE_VALIDATION_INPUT.REQUERID_FIELD),
  documento: Yup.string()
    .min(5)
    .max(255)
    .required(MESSAGE_VALIDATION_INPUT.REQUERID_FIELD),
  nomeContato: Yup.string()
    .min(5)
    .max(255)
    .required(MESSAGE_VALIDATION_INPUT.REQUERID_FIELD),
  status: Yup.string().required(MESSAGE_VALIDATION_INPUT.REQUERID_FIELD),
  anotacoes: Yup.string().nullable(),
});

const dominios = Object.values(DOMINIO_STATUS_EMPRESA);

function Public({ empresa, update, handleClose }) {
  const [salvando, setSalvando] = useState(false);
  const [salvandoLogomarca, setSalvandoLogomarca] = useState(false);

  const uploadLogomarca = (response) => {
    setSalvandoLogomarca(true);
    EmpresaService.logomarcaSuporte(response.target.files[0], empresa.idEmpresa)
      .then((e) => {
        Alert.success("Sucesso", "Logomarca alterada com sucesso");
        update(e.data);
      })
      .finally(() => setSalvandoLogomarca(false));
  };

  const updateEmpresa = (values) => {
    let emails = "";

    values.email.map((item) => {
      emails = emails + item + ";";
    });

    setSalvando(true);
    EmpresaService.updateEmpresaSuporte({
      anotacoes: values.anotacoes,
      idEmpresa: empresa.idEmpresa,
      nomeContato: values.nomeContato,
      status: values.status,
      nome: values.nome,
      razaoSocial: values.razaoSocial,
      documento: values.documento,
      email: emails,
      telefone: values.telefone,
    })
      .then((ret) => {
        Alert.success("Sucesso", "Credenciada alterada com sucesso");
        update(ret);
      })
      .finally(() => {
        setSalvando(false);
      });
  };

  const save = (data) => {
    let emails = "";

    data.email.map((item) => {
      emails = emails + item + ";";
    });

    setSalvando(true);

    EmpresaService.createEmpresaEnderecoSuporte({
      idEndereco: data.idEmpresa,
      cep: data.cep,
      endereco: data.endereco,
      numero: data.numero,
      bairro: data.bairro,
      estado: data.estado,
      cidade: data.cidade,
      complemento: data.complemento,
      anotacoes: data.anotacoes,
      nomeContato: data.nomeContato,
      status: data.status,
      nome: data.nome,
      razaoSocial: data.razaoSocial,
      documento: data.documento,
      email: emails,
      telefone: data.telefone,
    })
      .then((ret) => {
        Alert.success("Sucesso", "Empresa criada com sucesso");
        handleClose();
      })
      .finally(() => {
        setSalvando(false);
      });
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Informações da Credenciada
        </Typography>

        <Formik
          initialValues={{
            nome: !empresa ? undefined : empresa.nome,
            razaoSocial: !empresa ? undefined : empresa.razaoSocial,
            documento: !empresa ? undefined : empresa.documento,
            email:
              !empresa || !empresa.email ? undefined : empresa.email.split(";"),
            telefone: !empresa ? undefined : empresa.telefone,
            nomeContato: !empresa ? undefined : empresa.nomeContato,
            status: !empresa ? undefined : empresa.status,
            anotacoes: !empresa ? undefined : empresa.anotacoes,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            if (empresa) {
              updateEmpresa(values);
            } else {
              save(values);
            }
          }}
        >
          {(formik) => (
            <form noValidate onSubmit={formik.handleSubmit}>
              <Grid container spacing={5}>
                <Grid item md={empresa ? 8 : 12} sm={12} xs={12}>
                  <Grid item md={12} sm={12} xs={12}>
                    <TextField
                      id="nome"
                      label="Nome Fantasia"
                      variant="outlined"
                      style={{ marginTop: 15 }}
                      fullWidth
                      required
                      my={2}
                      error={Boolean(formik.touched.nome && formik.errors.nome)}
                      helperText={formik.touched.nome && formik.errors.nome}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.nome}
                    />
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <TextField
                      id="razaoSocial"
                      label="Razão Social"
                      variant="outlined"
                      style={{ marginTop: 15 }}
                      fullWidth
                      required
                      my={2}
                      error={Boolean(
                        formik.touched.razaoSocial && formik.errors.razaoSocial
                      )}
                      helperText={
                        formik.touched.razaoSocial && formik.errors.razaoSocial
                      }
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.razaoSocial}
                    />
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <TextFieldCNPJ
                      id="documento"
                      label="CNPJ"
                      variant="outlined"
                      style={{ marginTop: 15 }}
                      fullWidth
                      required
                      my={2}
                      error={Boolean(
                        formik.touched.documento && formik.errors.documento
                      )}
                      helperText={
                        formik.touched.documento && formik.errors.documento
                      }
                      onBlur={formik.handleBlur}
                      onChange={(e) => formik.setFieldValue("documento", e)}
                      value={formik.values.documento}
                    />
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <Autocomplete
                      multiple
                      id="tags-filled"
                      options={[]}
                      freeSolo
                      style={{ marginTop: 15 }}
                      value={formik.values.email}
                      onChange={(event, value, reason, details) => {
                        formik.setFieldValue("email", value);
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          fullWidth
                          variant="outlined"
                          error={Boolean(
                            formik.touched.email && formik.errors.email
                          )}
                          onBlur={formik.handleBlur}
                          helperText={
                            !formik.touched.email && !formik.errors.email
                              ? "Para adicionar um novo aperte enter"
                              : formik.touched.email && formik.errors.email
                          }
                          label="Email de Faturamento"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <TextFieldTelefone
                      id="telefone"
                      label="Telefone"
                      variant="outlined"
                      style={{ marginTop: 15 }}
                      fullWidth
                      required
                      my={2}
                      value={formik.values.telefone}
                      error={Boolean(
                        formik.touched.telefone && formik.errors.telefone
                      )}
                      helperText={
                        formik.touched.telefone && formik.errors.telefone
                      }
                      onBlur={formik.handleBlur}
                      onChange={(ret) => {
                        formik.setFieldValue("telefone", ret, true);
                      }}
                    />
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <TextField
                      id="nomeContato"
                      label="Nome do Contato"
                      variant="outlined"
                      style={{ marginTop: 15 }}
                      fullWidth
                      required
                      my={2}
                      error={Boolean(
                        formik.touched.nomeContato && formik.errors.nomeContato
                      )}
                      helperText={
                        formik.touched.nomeContato && formik.errors.nomeContato
                      }
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.nomeContato}
                    />
                  </Grid>

                  <Grid item md={12} sm={12} xs={12} style={{ marginTop: 15 }}>
                    <FormControl variant="outlined" fullWidth={true}>
                      <InputLabel htmlFor="outlined-status-native-simple">
                        Status
                      </InputLabel>
                      <Select
                        label="Status"
                        inputProps={{
                          name: "status",
                          id: "outlined-status-native-simple",
                        }}
                        native
                        error={Boolean(
                          formik.touched.status && formik.errors.status
                        )}
                        helperText={
                          formik.touched.status && formik.errors.status
                        }
                        onBlur={formik.handleBlur}
                        onChange={(e) => {
                          formik.setFieldValue("status", e.target.value);
                        }}
                        value={formik.values.status}
                      >
                        <option value={undefined}>Selecione</option>
                        {dominios.map((item) => (
                          <option
                            value={item.cod}
                            selected={item.cod === formik.values.status}
                          >
                            {item.name}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item md={12} sm={12} xs={12}>
                    <TextField
                      id="anotacoes"
                      label="Anotações"
                      variant="outlined"
                      style={{ marginTop: 15 }}
                      fullWidth
                      multiline
                      rows={5}
                      my={2}
                      error={Boolean(
                        formik.touched.anotacoes && formik.errors.anotacoes
                      )}
                      helperText={
                        formik.touched.anotacoes && formik.errors.anotacoes
                      }
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.anotacoes}
                    />
                  </Grid>
                </Grid>

                {empresa && (
                  <Grid item md={4} sm={12} xs={12}>
                    <Grid item md={12} sm={12} xs={12}>
                      {salvandoLogomarca ? (
                        <Loading />
                      ) : (
                        <CenteredContent>
                          <Box ml={1}>
                            {empresa.logomarca && (
                              <img
                                alt="logomarca"
                                width={190}
                                height={70}
                                src={empresa.logomarca}
                              />
                            )}
                          </Box>

                          <input
                            accept="image/*"
                            style={{ display: "none" }}
                            id="raised-button-file"
                            type="file"
                            onChange={(value) => uploadLogomarca(value)}
                          />
                          <label htmlFor="raised-button-file">
                            <Button
                              variant="contained"
                              color="primary"
                              component="span"
                            >
                              <CloudUpload mr={2} /> Upload
                            </Button>

                            <Typography
                              variant="caption"
                              display="block"
                              gutterBottom
                            >
                              Para obter os melhores resultados, utilize uma
                              imagem de boa resolução
                            </Typography>
                          </label>
                        </CenteredContent>
                      )}
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Box my={8} />
              {salvando ? (
                <Loading />
              ) : empresa ? (
                <AlterarButton />
              ) : (
                <CadastrarButton />
              )}
            </form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
}

function EditarEmpresa({ show = false, onRequestClose, data }) {
  const [showDialog, setShowDialog] = useState(show);
  const [empresa, setEmpresa] = useState();
  const [carregandoEndereco, setCarregandoEndereco] = useState(false);
  const [salvando, setSalvando] = useState(false);
  const [carregando, setCarregando] = useState(false);

  useEffect(() => {
    if (data && data.idEmpresa) {
      findById();
    }
  }, []);

  const findById = () => {
    if (data && data.idEmpresa) {
      setCarregando(true);
      EmpresaService.findById(data.idEmpresa)
        .then((ret) => {
          setEmpresa(ret);
        })
        .finally(() => {
          setSalvando(false);
          setCarregando(false);
          setCarregandoEndereco(false);
        });
    }
  };

  const update = (endereco) => {
    setSalvando(true);
    EmpresaService.updateEmpresaEnderecoSuporte({
      idEndereco: data.idEmpresa,
      cep: endereco.cep,
      endereco: endereco.endereco,
      numero: endereco.numero,
      bairro: endereco.bairro,
      estado: endereco.estado,
      cidade: endereco.cidade,
      complemento: endereco.complemento,
    })
      .then((ret) => {
        Alert.success("Sucesso", "Endereço alterado com sucesso");
        setEmpresa(ret);
      })
      .finally(() => {
        setSalvando(false);
        setCarregandoEndereco(false);
      });
  };

  const handleClose = () => {
    setShowDialog(false);
    onRequestClose();
  };

  return (
    <Dialog
      title={`${data ? "Alterar" : "Cadastrar"} Credenciada`}
      show={showDialog}
      maxWidth={"lg"}
      onRequestClose={() => handleClose()}
    >
      {carregando ? (
        <Loading />
      ) : (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Public
              empresa={empresa}
              update={(e) => setEmpresa(e)}
              handleClose={handleClose}
            />
            {data && (
              <FormEndereco
                carregandoEndereco={carregandoEndereco}
                salvando={salvando}
                endereco={empresa}
                setEndereco={setEmpresa}
                update={update}
              />
            )}
          </Grid>
        </Grid>
      )}
    </Dialog>
  );
}

export default EditarEmpresa;
