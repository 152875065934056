import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Grid as MuiGrid,
  Paper,
  Typography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import _ from "lodash";
import { PickList } from "primereact/picklist";
import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";

import SalvarButton from "../../components/icarus/buttons/SalvarButton";
import TextField from "../../components/icarus/textfield/TextField";

import EmpresaPontoVendaService from "../../services/EmpresaPontoVendaService";
import Dialog from "../../components/Dialog";
import Loading from "../../components/Loading";
import Alert from "../../components/alert/Alert";
import { Close } from "@material-ui/icons";

const Grid = styled(MuiGrid)(spacing);

const CredenciadaPontoVenda = ({ show, empresa, onClose }) => {
  const [showDialog, setShowDialog] = useState(show);

  const [carregando, setCarregando] = useState(false);
  const [pontosVenda, setPontosVenda] = useState([]);
  const [pontosVendaSemFiltro, setpontosVendaSemFiltro] = useState([]);
  const [selecionados, setSelecionados] = useState([]);

  useEffect(() => {
    if (empresa) {
      setCarregando(true);
      EmpresaPontoVendaService.buscarVinculadosNaoVinculados(empresa.idEmpresa)
        .then((retorno) => {
          setSelecionados(retorno.vinculados);
          setPontosVenda(retorno.naoVinculados);
          setpontosVendaSemFiltro(retorno.naoVinculados);
        })
        .finally(() => setCarregando(false));
    }
  }, []);

  const onRequestClose = () => {
    onClose();
    setShowDialog(false);
  };

  const salvar = () => {
    setCarregando(true);
    EmpresaPontoVendaService.vinculaLote(selecionados, empresa)
      .then(() => {
        Alert.success("Sucesso", "Pontos de Venda Vinculados com Sucesso");
        setPontosVenda([]);
        setSelecionados([]);
        onRequestClose();
      })
      .finally(() => setCarregando(false));
  };

  const onChange = (e) => {
    setSelecionados(e.target);
    setPontosVenda(e.source);
  };

  const itemTemplate = (item) => {
    return (
      <Grid container>
        <Grid item style={{ alignSelf: "center" }} md={12} sm={12} xs={12}>
          <Typography variant="subtitle2" style={{ marginLeft: 10 }}>
            {item.descricao}
          </Typography>
          <Typography variant="caption" style={{ marginLeft: 10 }}>
            {item.nomeMaquina}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const onFilter = (filtro) => {
    if (!filtro || filtro === "") {
      setPontosVenda(pontosVendaSemFiltro);
      return;
    }

    setPontosVenda(
      _.filter(pontosVendaSemFiltro, function (o) {
        return o.pessoa.nome.toLowerCase().includes(filtro.toLowerCase());
      })
    );
  };

  const setSelecionadosFiltro = (ret) => {
    const aux = [];

    ret.forEach((ite) => {
      let exists = false;

      selecionados.forEach((re) => {
        if (re.idMutuario === ite.idMutuario) {
          exists = true;
        }
      });

      if (!exists) {
        aux.push(ite);
      }
    });

    setPontosVenda(aux);
  };

  const naoVinculados = () => {
    return (
      <>
        <text>Não Selecionados</text>
        <Box my={1}></Box>
        <TextField
          fullWidth
          onChange={(e) => {
            onFilter(e.target.value);
          }}
          label="Filtrar"
        ></TextField>
      </>
    );
  };

  return (
    <React.Fragment>
      <Dialog
        title={`Vincular Pontos Venda`}
        show={showDialog}
        onRequestClose={onRequestClose}
      >
        <Grid container spacing={6} md={12} sm={12} xs={12} direction="column">
          <Grid item md={12} sm={12} xs={12}>
            <PickList
              sourceStyle={{ maxHeight: "242px", minHeight: "242px" }}
              targetStyle={{ maxHeight: "242px", minHeight: "282px" }}
              source={pontosVenda}
              target={selecionados}
              itemTemplate={itemTemplate}
              showTargetControls={false}
              showSourceControls={false}
              onChange={onChange}
              sourceHeader={naoVinculados()}
              targetHeader={"Vinculados"}
            />
          </Grid>

          <Box my={2} />
        </Grid>
        <Grid container spacing={3}>
          <Grid item>
            {carregando ? (
              <Loading />
            ) : (
              empresa && <SalvarButton onClick={() => salvar()} />
            )}
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Close />}
              onClick={() => onRequestClose()}
            >
              Fechar
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </React.Fragment>
  );
};

export default CredenciadaPontoVenda;
