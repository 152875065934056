//import axios from "../utils/axios";
import Axios from "axios";

function buscar() {
  return new Promise((resolve, reject) => {
    Axios.get(`/empresa`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function updateEmpresaUsuarioLogado({
  nome,
  razaoSocial,
  documento,
  email,
  telefone,
}) {
  return new Promise((resolve, reject) => {
    const vo = {
      nome,
      razaoSocial,
      documento,
      email,
      telefone,
    };
    Axios.put(`/empresa`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function updateEmpresaSuporte({
  anotacoes,
  nomeContato,
  status,
  nome,
  razaoSocial,
  documento,
  email,
  telefone,
  idEmpresa,
}) {
  return new Promise((resolve, reject) => {
    const vo = {
      anotacoes,
      idEmpresa,
      nome,
      razaoSocial,
      documento,
      email,
      telefone,
      nomeContato,
      status,
    };
    Axios.put(`/empresa/suporte`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function updateEmpresaEnderecoUsuarioLogado({
  cep,
  endereco,
  numero,
  bairro,
  estado,
  cidade,
  complemento,
}) {
  return new Promise((resolve, reject) => {
    const vo = {
      cep: cep,
      endereco: endereco,
      numero: numero,
      bairro: bairro,
      estado: estado,
      cidade: cidade,
      complemento: complemento,
    };
    Axios.put(`/empresa/endereco`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function updateEmpresaEnderecoSuporte({
  idEndereco,
  cep,
  endereco,
  numero,
  bairro,
  estado,
  cidade,
  complemento,
}) {
  return new Promise((resolve, reject) => {
    const vo = {
      idEndereco: idEndereco,
      cep: cep,
      endereco: endereco,
      numero: numero,
      bairro: bairro,
      estado: estado,
      cidade: cidade,
      complemento: complemento,
    };
    Axios.put(`/empresa/suporte/endereco`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function buscarToken() {
  return new Promise((resolve, reject) => {
    Axios.get(`/empresa/buscarToken`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function logomarca(file) {
  const formData = new FormData();
  formData.append("file", file);
  return Axios.put("/empresa/logomarca", formData, {
    headers: { "content-type": "multipart/form-data;" },
  });
}

function logomarcaSuporte(file, idEmpresa) {
  const formData = new FormData();
  formData.append("file", file);
  return Axios.put(`/empresa/suporte/logomarca/${idEmpresa}`, formData, {
    headers: { "content-type": "multipart/form-data;" },
  });
}

function pesquisa(
  nome,
  page = 0,
  documento = undefined,
  tipo = undefined,
  statusNfse = undefined,
  modoCobranca
) {
  return new Promise((resolve, reject) => {
    const vo = {
      nome,
      documento,
      modoCobranca,
      filtro: tipo,
      nfseStatus: statusNfse,
    };

    Axios.post(`/empresa/pesquisa/${page}`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function findById(idEmpresa) {
  return new Promise((resolve, reject) => {
    Axios.get(`/empresa/${idEmpresa}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function deletar(idEmpresa) {
  return new Promise((resolve, reject) => {
    Axios.delete(`/empresa/${idEmpresa}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function validarEmpresa(idEmpresa) {
  return new Promise((resolve, reject) => {
    Axios.get(`/empresa/validarEmpresa/${idEmpresa}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function buscarEmpresasCartaoNaoAprovado() {
  return new Promise((resolve, reject) => {
    Axios.get(`/empresa/buscarEmpresasCartaoNaoAprovado`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function createEmpresaEnderecoSuporte({
  idEndereco,
  cep,
  endereco,
  numero,
  bairro,
  estado,
  cidade,
  complemento,
  anotacoes,
  nomeContato,
  status,
  nome,
  razaoSocial,
  documento,
  email,
  telefone,
}) {
  return new Promise((resolve, reject) => {
    const vo = {
      idEndereco,
      cep,
      endereco,
      numero,
      bairro,
      estado,
      cidade,
      complemento,
      anotacoes,
      nomeContato,
      status,
      nome,
      razaoSocial,
      documento,
      email,
      telefone,
    };
    Axios.post(`/empresa`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const EmpresaService = {
  updateEmpresaUsuarioLogado,
  buscar,
  logomarca,
  buscarToken,
  buscarEmpresasCartaoNaoAprovado,
  findById,
  updateEmpresaEnderecoUsuarioLogado,
  pesquisa,
  logomarcaSuporte,
  updateEmpresaSuporte,
  updateEmpresaEnderecoSuporte,
  deletar,
  validarEmpresa,
  createEmpresaEnderecoSuporte,
};

export default EmpresaService;
