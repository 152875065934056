import { FormControl, InputLabel, Select } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";

const SelectMenu = (props) => {
  return (
    <FormControl fullWidth={props.fullWidth} variant="outlined">
      <InputLabel required={props.required} id={`${props.id}_${props.label}`}>
        {props.label}
      </InputLabel>
      <Select required labelId={`${props.id}_${props.label}`} {...props}>
        {props.children}
      </Select>
    </FormControl>
  );
};

SelectMenu.propTypes = {
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
};

SelectMenu.defaultProps = {
  fullWidth: true,
  required: false,
};

export default SelectMenu;
