import Axios from "axios";

//Axios.defaults.baseURL = "https://backendfgts.consulty.net.br"
//Axios.defaults.baseURL = "https://backend.rbscredi.com.br"
//Axios.defaults.baseURL = "https://backendfgtsdev.rbscredi.com.br"
//Axios.defaults.baseURL = "http://localhost:8080";
//Axios.defaults.baseURL = "https://detrandev.klart.com.br";
Axios.defaults.baseURL = process.env.REACT_APP_HOST_IP_ADDRESS;

Axios.defaults.headers.post["Content-Type"] = "application/json; charset=utf8";
Axios.defaults.headers.put["Content-Type"] = "application/json; charset=utf8";
Axios.defaults.headers.delete["Content-Type"] =
  "application/json; charset=utf8";
